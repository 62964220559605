import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Divider,
  IconButton,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TablePagination
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronDown, X, Eye } from 'lucide-react';
import { getAllClubsSubscriptions } from 'src/actions/clubActions';
import { getProductTransactions } from 'src/actions/donationAction';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  searchContainer: {
    marginBottom: theme.spacing(3)
  },
  accordion: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%',
    '&:before': {
      display: 'none',
    }
  },
  accordionDetails: {
    padding: 0,
    display: 'block',
    width: '100%',
  },
  tableCell: {
    verticalAlign: 'baseline',
    '&.subscription-cell': {
    }
  },
  summaryText: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& .count': {
      backgroundColor: theme.palette.action.selected,
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.shape.borderRadius,
      fontSize: '0.875rem',
    }
  },
  subscriptionList: {
    width: '100%',
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  listItem: {
    display: 'flex',
    padding: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    }
  },
  subscriptionDetails: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  subscriptionStats: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexWrap: 'nowrap',
    '& > div': {
      flex: '1 1 0',
      minWidth: '100px',
      maxWidth: '150px',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      '&:first-child': {
        minWidth: '50px',
        flex: '0 0 auto',
        minWidth: 'auto',
        marginLeft: 'auto',
      },
      '&:nth-child(2)': {
        flex: '2 1 0',
        minWidth: '150px',
      },
      '&:last-child': {
        flex: '0 0 auto',
        minWidth: 'auto',
        marginLeft: 'auto',
      }
    }
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
  },
  modalContent: {
    padding: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  statusChip: {
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.75rem',
    fontWeight: 500,
    '&.success': {
      backgroundColor: "#91d594",
      color: "#49a94d",
    },
    '&.pending': {
      backgroundColor: "#fff974",
      color: "#e39038",
    },
    '&.failed': {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.dark,
    }
  },
  transactionsTable: {
    '& th': {
      fontWeight: 600,
      backgroundColor: theme.palette.background.default,
    }
  },
  viewTransactionsButton: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginLeft: 'auto'
  },
  statsLabel: {
    marginBottom: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  statsValue: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const TransactionsModal = ({ open, onClose, productTitle, clubName, productId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const pageSize = 10;

  const { content: transactions = [], totalElements = 0 } = useSelector(
    (state) => state.donation.productTransactions
  );

  // Reset page when modal closes or product changes
  useEffect(() => {
    if (!open) {
      setPage(0);
    }
  }, [open, productId]);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (open && productId) {
        setLoading(true);
        try {
          const result = await dispatch(getProductTransactions(productId, page, pageSize));
          console.log('Fetched transactions:', result);
        } catch (error) {
          console.error('Error fetching transactions:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchTransactions();
  }, [dispatch, open, productId, page]);

  // Material-UI expects onChangePage, not onPageChange
  const handleChangePage = (event, newPage) => {
    console.log('Changing to page:', newPage);
    setPage(newPage);
  };

  const getStatusDisplay = (status, stripeStatus) => {
    if (status && !stripeStatus) {
      return <span className={`${classes.statusChip} pending`}>In Verarbeitung</span>;
    } else if (!status) {
      return <span className={`${classes.statusChip} failed`}>Fehlgeschlagen</span>;
    }
    return <span className={`${classes.statusChip} success`}>Abgeschlossen</span>;
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('de-DE', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className={classes.modalHeader}>
        <div>
          <Typography variant="h6">{clubName}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {productTitle} - Transaktionsverlauf
          </Typography>
        </div>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.modalContent}>
        <Paper>
          {loading ? (
            <Box display="flex" justifyContent="center" p={3}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Table className={classes.transactionsTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>Datum</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Betrag</TableCell>
                    <TableCell>Zahlungsmethode</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.length > 0 ? (
                    transactions.map((transaction) => (
                      <TableRow key={transaction.id} hover>
                        <TableCell>{formatDate(transaction.createdDate)}</TableCell>
                        <TableCell>{transaction.userName}</TableCell>
                        <TableCell>CHF {transaction.amount?.toFixed(2)}</TableCell>
                        <TableCell>{transaction.paymentMethod}</TableCell>
                        <TableCell>
                          {getStatusDisplay(transaction.status, transaction.stripeStatus)}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Keine Transaktionen gefunden
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {transactions.length > 0 && (
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <TablePagination
                    component="div"
                    count={totalElements}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[]}
                    labelDisplayedRows={({ from, to, count }) => {
                      const adjustedFrom = (page * pageSize) + 1;
                      const adjustedTo = Math.min((page + 1) * pageSize, count);
                      return `${adjustedFrom}-${adjustedTo} von ${count}`;
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

const SubscriptionsView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedPanel, setExpandedPanel] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedClub, setSelectedClub] = useState(null);

  const clubs = useSelector((state) => state.club?.clubsSubscriptions || []);

  useEffect(() => {
    dispatch(getAllClubsSubscriptions());
  }, [dispatch]);

  const filteredClubs = clubs.filter(club =>
    club.clubName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAccordionChange = (clubId) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? clubId : null);
  };

  const handleOpenTransactions = (subscription, club) => {
    setSelectedSubscription(subscription);
    setSelectedClub(club);
    setModalOpen(true);
  };

  const renderSubscriptionDetails = (subscriptions, club) => (
    <List className={classes.subscriptionList}>
      {subscriptions.map((sub, index) => (
        <React.Fragment key={sub.productId}>
          <ListItem className={classes.listItem}>
            <div className={classes.subscriptionDetails}>
              <div className={classes.subscriptionStats}>
                <div>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.statsLabel}
                  >
                    ID
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.statsValue}
                  >
                    {sub.productId}
                  </Typography>
                </div>

                <div>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.statsLabel}
                    title={sub.title}
                  >
                    Name
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.statsValue}
                    title={sub.title}
                  >
                    {sub.title}
                  </Typography>
                </div>

                <div>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.statsLabel}
                  >
                    Preis
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.statsValue}
                  >
                    CHF {sub.price}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.statsLabel}
                  >
                    Abonnenten
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.statsValue}
                  >
                    {sub.activeSubscribersCount}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.statsLabel}
                  >
                    Einnahmen
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.statsValue}
                  >
                    CHF {parseFloat(sub.totalRevenue || 0).toFixed(2)}
                  </Typography>
                </div>

                <div>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.statsLabel}
                  >
                    Periode
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.statsValue}
                  >
                    {sub.recurringPeriod}
                  </Typography>
                </div>

                <div className={classes.iconContainer}>
                  <IconButton
                    className={classes.viewTransactionsButton}
                    onClick={() => handleOpenTransactions(sub, club)}
                    size="small"
                  >
                    <Eye size={20} />
                  </IconButton>
                </div>
              </div>
            </div>
          </ListItem>
          {index < subscriptions.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <div className={classes.root}>
      <Box className={classes.searchContainer}>
        <TextField
          fullWidth
          placeholder="Clubs suchen..."
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>

      <Card>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Club ID</TableCell>
                  <TableCell>Club Name</TableCell>
                  <TableCell className="subscription-cell">Abonnement-Produkte</TableCell>
                  <TableCell>Gesamteinnahmen</TableCell>
                  <TableCell>Aktive Abonnenten</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredClubs.length > 0 ? (
                  filteredClubs.map((club) => {
                    const totalRevenue = club.subscriptions.reduce(
                      (sum, sub) => sum + parseFloat(sub.totalRevenue || 0),
                      0
                    );
                    const totalSubscribers = club.subscriptions.reduce(
                      (sum, sub) => sum + (sub.activeSubscribersCount || 0),
                      0
                    );

                    return (
                      <TableRow hover key={club.clubId}>
                        <TableCell className={classes.tableCell}>{club.clubId}</TableCell>
                        <TableCell className={classes.tableCell}>{club.clubName}</TableCell>
                        <TableCell className={`${classes.tableCell} subscription-cell`}>
                          <Accordion
                            className={classes.accordion}
                            expanded={expandedPanel === club.clubId}
                            onChange={handleAccordionChange(club.clubId)}
                          >
                            <AccordionSummary
                              expandIcon={<ChevronDown />}
                            >
                              <div className={classes.summaryText}>
                                <span>Abonnements anzeigen</span>
                                <span className="count">{club.subscriptions.length}</span>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              {renderSubscriptionDetails(club.subscriptions, club)}
                            </AccordionDetails>
                          </Accordion>
                        </TableCell>
                        <TableCell className={classes.tableCell}>CHF {totalRevenue.toFixed(2)}</TableCell>
                        <TableCell className={classes.tableCell}>{totalSubscribers}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      {searchTerm ? 'Keine Clubs entsprechen Ihrer Suche' : 'Keine Abonnement-Daten verfügbar'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>

      <TransactionsModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        productTitle={selectedSubscription?.title}
        clubName={selectedClub?.clubName}
        productId={selectedSubscription?.productId}
      />
    </div>
  );
};

export default SubscriptionsView;