import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Page from 'src/components/Page';
import { getClubTransactionById } from 'src/actions/donationAction';
import { baseUrl } from 'src/utils/helper';

export const getFileURL = async (fileName, userData) => {
  const fileObj = await fetch(`${baseUrl}/files/name`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${userData.token}`,
    },
    body: JSON.stringify({
      fileName,
    }),
  });
  const fileUrl = await fileObj.text();
  return fileUrl;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  details: {
    padding: 30
  },
  address: {
    listStyleType: 'none',
    marginLeft: 15,
    marginRight: 15
  },
  infoGrids: {
    minHeight: 185
  },
  approveBtn: {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  informationList: {
    listStyleType: 'none'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  valueTxt: {
    paddingTop: 5,
    paddingBottom: 5,
    fontWeight: 500
  },
  header: {
    display: 'flex'
  },
  arrowIcon: {
    margin: 10
  }
}));

const TransactionDetailView = (props) => {
  const [pdfLink, setPdfLink] = useState('');
  const { getClubTransactionById: fetchTransactionById, transactionDetails, authData } = props;
  const { id } = useParams();
  const classes = useStyles();
  useEffect(() => {
    fetchTransactionById({ transactionId: id });
  }, [fetchTransactionById]);

  useEffect(() => {
    async function getFile() {
      if (transactionDetails.payoutDetails && transactionDetails.payoutDetails.fileUrl) {
        const url = await getFileURL(transactionDetails.payoutDetails.fileUrl, authData);
        setPdfLink(url);
      }
    }
    getFile();
  }, [transactionDetails.payoutDetails]);

  const displayAddress = (address) => {
    if (address && address.address1) {
      return (
        <p className={classes.valueTxt}>
          {address.address1 || null}
          <br />
          {address.address2 || null}
          <br />
          {address.city || null}
          <br />
          {address.country || null}
          <br />
          {address.postalCode || null}
          <br />
        </p>
      );
    }
    return 'N/A';
  };

  return (
    <Page
      className={classes.root}
      title="Details zur Transaktion"
    >
      <Container maxWidth={false}>
        <div className={classes.header}>
          <a href="/app/transactions">
            <ArrowBackIcon className={classes.arrowIcon} />
          </a>
          <h1>
          Details zur Transaktion
            {' '}
            {id}
          </h1>
        </div>
        <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <Card>
                <CardHeader
                  title="Informationen zum Sponsor"
                />
                <Divider />
                <CardContent className={classes.infoGrids}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Vorname</div>
                        <p className={classes.valueTxt}>{transactionDetails.userDetails.firstName || 'N/A'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Nachname</div>
                        <p className={classes.valueTxt}>{transactionDetails.userDetails.lastName || 'N/A'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>E-Mail</div>
                        <p className={classes.valueTxt}>{transactionDetails.userDetails.email || 'N/A'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.flexCol}>
                        <div>Adresse</div>
                        <span>{displayAddress(transactionDetails.userDetails.address)}</span>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <Card>
                <CardHeader
                  title="Club-Informationen"
                />
                <Divider />
                <CardContent className={classes.infoGrids}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Club name</div>
                        <p className={classes.valueTxt}>{transactionDetails.clubDetails.clubName || 'N/A'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Vorname des Verkäufers</div>
                        <p className={classes.valueTxt}>{transactionDetails.clubDetails.salespersonFirstName || 'N/A'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Nachname des Verkäufers</div>
                        <p className={classes.valueTxt}>{transactionDetails.clubDetails.salespersonLastName || 'N/A'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.flexCol}>
                        <div>Adresse des Clubs</div>
                        <span>{displayAddress(transactionDetails.clubDetails.clubAddress)}</span>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <Card>
                <CardHeader
                  title="Informationen zur Transaktion"
                />
                <Divider />
                <CardContent className={classes.infoGrids}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Betrag der Kommission</div>
                        <p className={classes.valueTxt}>{transactionDetails.transactionDetails.commissionAmount || 'N/A'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Betrag der Spende</div>
                        <p className={classes.valueTxt}>{transactionDetails.transactionDetails.donationAmount || 'N/A'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Zahlungsmethode</div>
                        <p className={classes.valueTxt}>{transactionDetails.transactionDetails.paymentMethod || 'N/A'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Auszahlungsstatus</div>
                        <p className={classes.valueTxt}>{transactionDetails.transactionDetails.payoutStatus ? 'Yes' : 'No'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Betrag der Verkäuferprovision</div>
                        <p className={classes.valueTxt}>
                          {transactionDetails.transactionDetails.salespersonCommissionAmount
                        || 'N/A'}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Provisionssatz für Verkäufer</div>
                        <p className={classes.valueTxt}>
                          {transactionDetails.transactionDetails.salespersonCommissionRate
                        || 'N/A'}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Transaktionskosten</div>
                        <p className={classes.valueTxt}>{transactionDetails.transactionDetails.transactionCost || 'N/A'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Auszahlungsnummer</div>
                        <p className={classes.valueTxt}>{transactionDetails.payoutDetails ? transactionDetails.payoutDetails.id : 'N/A'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Auszahlungsdatum</div>
                        <p className={classes.valueTxt}>{moment(transactionDetails.payoutDetails && transactionDetails.payoutDetails.date || new Date()).format('YYYY-MM-DD') || 'N/A'}</p>
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Auszahlungsdokument</div>
                        {pdfLink ? <a href={pdfLink} download target='_blank'>Download</a> : null}
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Payment Intent (Stripe ID)</div>
                        <p className={classes.valueTxt}>{transactionDetails.transactionDetails.paymentIntent || 'N/A'}</p>
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div className={classes.flexCol}>
                        <div>Balance Transaction (Stripe ID)</div>
                        <p className={classes.valueTxt}>{transactionDetails.transactionDetails.balanceTxn || 'N/A'}</p>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  transactionDetails: state.donation.transactionDetails,
  authData: state.user.authUserData
});

const mapDispatchToProps = {
  getClubTransactionById
};

TransactionDetailView.propTypes = {
  getClubTransactionById: PropTypes.func.isRequired,
  transactionDetails: PropTypes.object
};

TransactionDetailView.defaultProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetailView);
