import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Chip,
  Button
} from '@material-ui/core';
import { dateFormatter } from 'src/utils/helper';
import ProductDetailModal from 'src/components/club/ClubModal';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  scrollX: {
    overflow: 'auto'
  },
  statusChip: {
    padding: '4px 12px',
    borderRadius: '4px',
    display: 'inline-block',
    fontWeight: 600,
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    letterSpacing: '0.5px'
  },
  statusEnabled: {
    backgroundColor: '#E8F5E9',
    color: '#2E7D32'
  },
  statusDisabled: {
    backgroundColor: '#FFEBEE',
    color: '#C62828'
  },
  typeChip: {
    padding: '4px 12px',
    borderRadius: '4px',
    display: 'inline-block',
    backgroundColor: '#EDE7F6',
    color: '#5E35B1',
    fontWeight: 500,
    fontSize: '0.75rem'
  },
  crowdfundingChip: {
    padding: '4px 12px',
    borderRadius: '4px',
    display: 'inline-block',
    backgroundColor: '#E3F2FD',
    color: '#1565C0',
    fontWeight: 500,
    fontSize: '0.75rem'
  }
}));

const ProductsList = ({
  className,
  products,
  pageDetails,
  handlePageChange,
  handlePageSizeChange,
  onProductUpdate,
  ...rest
}) => {
  const classes = useStyles();

  const startIndex = pageDetails.pageId * pageDetails.pageSize;
  const endIndex = startIndex + pageDetails.pageSize;
  const paginatedProducts = products.slice(startIndex, endIndex);
  const totalCount = products.length;

  const [logModalOpen, setLogModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleOpenLog = (product) => {
    setSelectedProduct(product);
    setLogModalOpen(true);
  };

  const getProductStatusChip = (status) => {
    return (
      <div
        className={clsx(
          classes.statusChip,
          status ? classes.statusEnabled : classes.statusDisabled
        )}
      >
        {status ? "AKTIV" : "INAKTIV"}
      </div>
    );
  };

  const getProductTypeChip = (type, isRecurring) => {
    let label = type;
    if (isRecurring) {
      label += ' (Wiederkehrend)';
    }
    return (
      <div className={classes.typeChip}>
        {label ? label?.toUpperCase() : 'N/A'}
      </div>
    );
  };

  const handleCloseModal = () => {
    setLogModalOpen(false);
    setSelectedProduct(null);
    if (onProductUpdate) {
      onProductUpdate();  // Call the update handler when modal closes
    }
  };

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box className={classes.scrollX}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Titel</TableCell>
                  <TableCell>Preis (CHF)</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Typ</TableCell>
                  <TableCell>Aktionen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedProducts && paginatedProducts.map((product) => (
                  <TableRow
                    hover
                    key={product.id}
                  >
                    <TableCell>
                      {product.id}
                    </TableCell>

                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {product.title}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell>
                      CHF {product.crowdFunding ? product.totalFunded+" / "+product.price : product.price}
                    </TableCell>

                    <TableCell>
                      {getProductStatusChip(product.status)}
                    </TableCell>

                    <TableCell>
                      {product.crowdFunding ? (
                        <div className={classes.crowdfundingChip}>
                          CROWDFUNDING
                        </div>
                      ) : getProductTypeChip(product.productType, product.recurringProduct)}
                    </TableCell>

                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenLog(product)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>

        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePageSizeChange}
          page={pageDetails.pageId}
          rowsPerPage={pageDetails.pageSize}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>

      <ProductDetailModal
        open={logModalOpen}
        onClose={handleCloseModal}
        product={selectedProduct}
      />
    </>
  );
};

ProductsList.propTypes = {
  className: PropTypes.string,
  products: PropTypes.array.isRequired,
  pageDetails: PropTypes.object.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handlePageSizeChange: PropTypes.func.isRequired,
  onProductUpdate: PropTypes.func
};

export default ProductsList;