import axios from 'axios';
import { baseUrl, authHeader } from 'src/utils/helper';
import { FETCH_CLUB_PRODUCTS } from 'src/utils/types';
import { refreshToken } from './userActions';
import displayLoader from './spinnerAction';

export const getClubProducts = (data, unfiltered = false) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displayLoader(true));
        const endpoint = unfiltered ? '/products/all-unfiltered' : '/products/all';
        const response = await axios.post(`${baseUrl}${endpoint}`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_CLUB_PRODUCTS,
            payload: response.data
          });
          dispatch(displayLoader(false));
        }
      }
    } catch (error) {
      dispatch(displayLoader(false));
      console.error('Error fetching club products:', error);
    }
  };
};

export const updateProductStatus = (productId, enabled) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displayLoader(true));
        const response = await axios.put(
          `${baseUrl}/products/update-status`,
          {
            id: productId,
            status: enabled
          },
          authHeader()
        );

        dispatch(displayLoader(false));
        return response.data;
      }
    } catch (error) {
      dispatch(displayLoader(false));
      console.error('Error updating product status:', error);
      throw error;
    }
  };
};