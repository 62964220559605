import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  CircularProgress,
  Box,
  makeStyles,
  Typography,
  Avatar,
  Chip,
  Tooltip
} from '@material-ui/core';
import {
  History as HistoryIcon,
  Check as CheckIcon,
  Close as CloseIcon
} from '@material-ui/icons';
import { getSubscriptionActivity } from 'src/actions/clubActions';
import { updateProductStatus } from 'src/actions/productActions';
import { dateFormatter } from 'src/utils/helper';


const useStyles = makeStyles((theme) => ({
  loadMoreButton: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2, 0)
  },
  listItem: {
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  content: {
    minHeight: '400px'
  },
  activityHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  activityDetails: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  activityIcon: {
    width: 20,
    height: 20,
    flexShrink: 0,
    padding: 2,
    borderRadius: "100%",
  },
  activityIconSubscribed: {
    color: theme.palette.success.main,
    backgroundColor: "#b5edb7",
  },
  activityIconUnsubscribed: {
    color: theme.palette.error.main,
    backgroundColor: "#ffd0cd",
  },
  scrollContainer: {
    maxHeight: '200px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.default
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.divider
    }
  },
  sectionContainer: {
    marginBottom: theme.spacing(3)
  },
  sectionTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(2)
  },
  historyContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper
  },
  historyTitle: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default
  },
  editSection: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius
  },
  editActions: {
   display: 'flex',
   gap: theme.spacing(2),
   marginTop: theme.spacing(2)
  },
  statusButton: {
    borderColor: theme.palette.grey[300],
    '&.enable': {
      color: theme.palette.success.main,
      borderColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.light,
        borderColor: theme.palette.success.main,
      }
    },
    '&.disable': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.light,
        borderColor: theme.palette.error.main,
      }
    }
  },
}));

const ProductDetailModal = ({ open, onClose, product }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSubscription = product?.productType?.toLowerCase() === 'subscription';
  const [updating, setUpdating] = useState(false);

  const [activities, setActivities] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    pageId: 0,
    pageSize: 15,
    totalElements: 0
  });
  const [loading, setLoading] = useState(false);

  const handleStatusUpdate = async (enable) => {
    if (!product?.id || updating) return;

    const action = enable ? 'aktivieren' : 'deaktivieren';
    const confirmMessage = `Sind Sie sicher, dass Sie dieses Produkt ${action} möchten?`;

    const confirmUpdate = window.confirm(confirmMessage);
    if (!confirmUpdate) return;

    setUpdating(true);
    try {
      await dispatch(updateProductStatus(product.id, enable));
      onClose();
    } catch (error) {
      console.error('Error updating product status:', error);
    } finally {
      setUpdating(false);
    }
  };

  const getActivityIcon = (type) => {
    switch (type.toLowerCase()) {
      case 'subscribed':
        return <CheckIcon className={`${classes.activityIcon} ${classes.activityIconSubscribed}`} />;
      case 'unsubscribed':
        return <CloseIcon className={`${classes.activityIcon} ${classes.activityIconUnsubscribed}`} />;
      default:
        return <HistoryIcon className={`${classes.activityIcon}`} />;
    }
  };

  const getChipClassName = (type) => {
    switch (type.toLowerCase()) {
      case 'subscribed':
        return classes.chipSubscribed;
      case 'unsubscribed':
        return classes.chipUnsubscribed;
      default:
        return classes.chipUpdate;
    }
  };

  const formatActivityType = (type) => {
    return (type === "SUBSCRIBED" ? "ANGEMELDET" : "ABGEMELDET").toLowerCase();
  };

  const formatDateTime = (date) => {
    return dateFormatter(date);
  };

  const loadActivities = async () => {
    if (!product?.id) return;

    setLoading(true);
    try {
      const data = await dispatch(getSubscriptionActivity(product.id, pageDetails.pageId, pageDetails.pageSize));
      if (data) {
        if (pageDetails.pageId === 0) {
          setActivities(data.content);
        } else {
          setActivities(prev => [...prev, ...data.content]);
        }
        setPageDetails(prev => ({
          ...prev,
          totalElements: data.totalElements
        }));
      }
    } catch (error) {
      console.error('Error while loading activity:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open && isSubscription) {
      setPageDetails(prev => ({ ...prev, pageId: 0 }));
      setActivities([]);
      loadActivities();
    }
  }, [open, product]);

  const handleLoadMore = () => {
    setPageDetails(prev => ({
      ...prev,
      pageId: prev.pageId + 1
    }));
    loadActivities();
  };

  const hasMore = activities.length < pageDetails.totalElements;

  const renderActivities = () => {
    if (loading && !activities.length) {
      return (
        <Box className={classes.loading}>
          <CircularProgress />
        </Box>
      );
    }

    if (!activities.length) {
      return (
        <Box p={2} textAlign="center">
          <Typography color="textSecondary">
            Keine Aktivitäten für dieses Produkt vorhanden
          </Typography>
        </Box>
      );
    }

    return (
      <div className={classes.historyContainer}>
        <div className={classes.historyTitle}>
          <Typography variant="subtitle1">
            Verlaufsprotokoll
          </Typography>
        </div>
        <Box className={classes.scrollContainer}>
          <List>
            {activities.map((activity, index) => (
              <ListItem key={index} className={classes.listItem}>
                <Box className={classes.activityHeader}>
                  {getActivityIcon(activity.activityType)}
                  {!activity.status ? (activity.date ? formatDateTime(activity.date) : 'Kein Datum angegeben') : formatDateTime(activity.date)} {' - '}
                  <b>{activity.userName}</b> {' hat sich '}
                  {formatActivityType(activity.activityType)}
                </Box>
              </ListItem>
            ))}
          </List>

          {hasMore && (
            <Box className={classes.loadMoreButton}>
              <Button
                onClick={handleLoadMore}
                color="primary"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Mehr laden'}
              </Button>
            </Box>
          )}
        </Box>
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Produktdetails</DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.editSection}>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Produkt bearbeiten
          </Typography>
          <Typography color="textSecondary">
            Bearbeitungsfunktion wird in Kürze implementiert.
          </Typography>
          <div className={classes.editActions}>
            <Button
              variant="outlined"
              className={`${classes.statusButton} ${product?.status ? 'disable' : 'enable'}`}
              onClick={() => handleStatusUpdate(!product?.status)}
              disabled={updating}
              startIcon={updating ? (
                <CircularProgress size={20} />
              ) : product?.status ? (
                <CloseIcon />
              ) : (
                <CheckIcon />
              )}
            >
              {product?.status ? 'Produkt deaktivieren' : 'Produkt aktivieren'}
            </Button>
          </div>
        </div>

        {isSubscription && renderActivities()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Schliessen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProductDetailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  product: PropTypes.object
};

export default ProductDetailModal;