import axios from 'axios';
import { baseUrl, authHeader } from 'src/utils/helper';
import
{
  FETCH_ALL_NOT_APPROVED_CLUBS, FETCH_ALL_CLUB_DETAILS,
  FETCH_CLUB_PERCENTAGE, FETCH_ALL_TYPES,
  FETCH_ALL_APPROVED_CLUBS,
  FETCH_CLUB_SALESPERSON_ID,
  FETCH_CLUBS_SUBSCRIPTIONS
} from 'src/utils/types';
import {
  displaySuccess, formRedirect, displayError, clearError, clearSuccess
} from './formResponse';
import { refreshToken } from './userActions';

import displayLoader from './spinnerAction';

export const getAllNtApprovedClubs = (onSuccess) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/clubs/unverified`, {}, authHeader());
        if (response && response.data) {
          console.log("API response:", response.data);
          if (typeof onSuccess === 'function') {
            onSuccess();
          }
          dispatch({
            type: FETCH_ALL_NOT_APPROVED_CLUBS,
            payload: response.data
          });
        }
      }
    } catch (error) {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
      console.error('there is an error', error);
    }
  };
};

export const getAllClubs = (data, onSuccess) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displayLoader(true));
        const response = await axios.post(`${baseUrl}/clubs/include-all`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_ALL_APPROVED_CLUBS,
            payload: response.data
          });
          dispatch(displayLoader(false));
          if (typeof onSuccess === 'function') {
            onSuccess();
          }
        }
      }
    } catch (error) {
      dispatch(displayLoader(false));
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
      console.error('there is an error', error);
    }
  };
};

export const getAllClubDetails = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/clubs/registration-details`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_ALL_CLUB_DETAILS,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const approveClub = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.put(`${baseUrl}/clubs/status`, data, authHeader());
        if (response) {
          dispatch(displaySuccess('Club Approved Successfully !'));
          setTimeout(() => {
            dispatch(formRedirect('/app/clubs'));
          }, 1500);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 1500);
      }
      console.error('there is an error', error);
    }
  };
};

export const getAllTypes = () => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/types/all`, {}, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_ALL_TYPES,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const createNewType = (data, onSuccess) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/types`, data, authHeader());
        if (response && response.data) {
          onSuccess();
          dispatch(displaySuccess('New Club Type Added Successfully !'));
          dispatch(getAllTypes());
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1500);
        }
      }
    } catch (error) {
      onSuccess();
      console.error('there is an error', error);
    }
  };
};

export const updateType = (data, onSuccess) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.put(`${baseUrl}/types`, data, authHeader());
        if (response && response.data) {
          onSuccess();
          dispatch(displaySuccess('Club Type Updated Successfully !'));
          dispatch(getAllTypes());
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1500);
        }
      }
    } catch (error) {
      onSuccess();
      console.error('there is an error', error);
    }
  };
};

export const getClubPercentage = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/percentages/filter`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_CLUB_PERCENTAGE,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const addClubPercentage = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/percentages`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess('Club Percentage Added Successfully !'));
          // Use the clubId from the original request
          dispatch(getClubPercentage({ clubId: data.clubId }));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1500);
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const updateClubPercentage = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.put(`${baseUrl}/percentages`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess('Club Percentage Updated Successfully !'));
          // Use the clubId from the original request
          dispatch(getClubPercentage({ clubId: data.clubId }));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1500);
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const getClubSalesperson = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/sales-person/filter`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_CLUB_SALESPERSON_ID,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const changeBlockStatus = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/clubs/block`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess(data.method === 'block' ? 'Club deaktiviert!' : 'Club aktiviert!'));
          dispatch(getAllClubDetails({ id: data.id }));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1500);
        }
      }
    } catch (error) {
      console.error('there is an error', error);
      dispatch(displayError('Status konnte nicht aktualisiert werden'));
    }
  };
};


export const updateClubAccountNumber= (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/clubs/account-number`, data, authHeader());
        if (response && response.data) {
            dispatch({
              type: FETCH_ALL_CLUB_DETAILS,
              payload: response.data
            });
          dispatch(displaySuccess('Club Account Number Updated Successfully !'));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1500);
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const getAllClubsSubscriptions = (onSuccess) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displayLoader(true));
        const response = await axios.get(
          `${baseUrl}/subscriptions/clubs-subscriptions`,
          authHeader()
        );
        console.log("API Response:", response.data); // Check API response
        if (response && response.data) {
          dispatch({
            type: FETCH_CLUBS_SUBSCRIPTIONS,
            payload: response.data
          });
          console.log("Dispatched payload:", response.data); // Verify dispatch
          dispatch(displayLoader(false));
          if (typeof onSuccess === 'function') {
            onSuccess();
          }
        }
      }
    } catch (error) {
      console.error('Error fetching clubs subscriptions:', error);
      dispatch(displayLoader(false));
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    }
  };
};

export const getSubscriptionActivity = (productId, pageId = 0, pageSize = 20) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displayLoader(true));
        const response = await axios.post(
          `${baseUrl}/subscriptions/products/activity`,
          {
            productId: productId.toString(),
            pageId: pageId.toString(),
            pageSize: pageSize.toString()
          },
          authHeader()
        );
        dispatch(displayLoader(false));
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching subscription activity:', error);
      dispatch(displayLoader(false));
      return null;
    }
  };
};